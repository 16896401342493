@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}
body {
  scrollbar-width: thin;
}
body::-webkit-scrollbar {
  display: none;
}
